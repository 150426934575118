import { render, staticRenderFns } from "./AdminProducts.vue?vue&type=template&id=5eaea394&scoped=true&"
import script from "./AdminProducts.vue?vue&type=script&lang=js&"
export * from "./AdminProducts.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/dataSetting.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./AdminProducts.vue?vue&type=style&index=1&id=5eaea394&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5eaea394",
  null
  
)

export default component.exports